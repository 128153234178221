import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { Box, Button, Card, Container, Link, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useQuery from "hooks/useQuery";

import InputField from "components/Core/Inputs/Input";
import VALIDATION from "helpers/validation_schemas";

import { useDialog } from "context/dialog";
import { createVideoSeries, getVideoSeries } from "api/api";
import Videos from "./Videos";
import pages from "enums/pages";
import { v4 as uuid } from "uuid";
import AddIcon from "@mui/icons-material/Add";

const EditVideoSeries = () => {
  const { id } = useParams();
  const { alertSuccess, alertError, showPageLoading } = useDialog();
  const [isLoading, setIsLoading] = useState(false);

  const { params } = useQuery();
  const isNew = params.new === "true";

  const [videoSeries, setVideoSeries] = useState<any>();

  const getData = useCallback(async () => {
    showPageLoading(true);
    try {
      if (!isNew && id) {
        const res: any = await getVideoSeries(id);
        setVideoSeries(res.videoSeries);
      }

      //
    } catch (err) {
      console.log(err);
    }
    showPageLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isNew]);

  useEffect(() => {
    getData();
  }, [getData]);

  const formik = useFormik({
    initialValues: {
      name: videoSeries?.name || "",
      description: videoSeries?.description || "",
    },
    enableReinitialize: true,
    validationSchema: VALIDATION.video_series_schema,
    onSubmit: async (values) => {
      if (!id) return;

      setIsLoading(true);
      try {
        await createVideoSeries(id, values);
        console.log(values);

        // remove query params
        window.history.pushState({}, document.title, window.location.pathname);

        alertSuccess("Video series updated successfully");
      } catch (err) {
        if (err instanceof Error) {
          console.log(err);
          alertError(err.message);
        }
      }

      setIsLoading(false);
    },
  });

  return (
    <Container style={{ padding: 0 }} maxWidth={"xl"}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          Create Video Series
        </Typography>
        {!isNew && videoSeries && (
          <Stack direction={"row"} gap={1}>
            <Button
              variant="contained"
              LinkComponent={Link}
              href={
                pages.VIDEOS_PAGE.replace(":id", uuid()) +
                "?new=true&seriesid=" +
                videoSeries?.id
              }
              startIcon={<AddIcon />}
            >
              New Video
            </Button>

            <Button
              variant="contained"
              startIcon={<AddIcon />}
              LinkComponent={Link}
              href={pages.VIDEOS_LIBRARY_PAGE + "?seriesid=" + videoSeries?.id}
            >
              Add Videos To Series
            </Button>
          </Stack>
        )}
      </Stack>
      <Card sx={{ p: 5 }}>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Stack gap={2}>
            <InputField
              label="Name"
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
            />

            <InputField
              label="Description"
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              fullWidth
              rows={4}
              multiline
            />

            <Box textAlign={"right"}>
              <LoadingButton
                type="submit"
                size="large"
                variant="contained"
                loading={isLoading}
              >
                Save Video Series
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Card>
      {!isNew && videoSeries && (
        <Card sx={{ mt: 2 }}>
          <Videos videos={videoSeries?.Videos ?? []} />
        </Card>
      )}
    </Container>
  );
};

export default EditVideoSeries;
