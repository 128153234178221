import * as yup from "yup";
import rules from "./rules";

export default {
  signup_schema: yup.object().shape({
    firstName: rules.validate_first_name,
    lastName: rules.validate_last_name,
    email: rules.validate_email,
    password: rules.validate_password,
    mobile: rules.validate_phone,
  }),
  signin_schema: yup.object().shape({
    email: rules.validate_email,
    password: rules.validate_password,
  }),
  forgot_password_schema: yup.object().shape({
    email: rules.validate_email,
  }),
  reset_password_schema: yup.object().shape({
    newPassword: rules.validate_password,
    confirmPassword: rules.validate_password,
  }),
  subscriber_schema: yup.object().shape({
    firstName: rules.validate_first_name,
    lastName: rules.validate_last_name,
    email: rules.validate_email,
    phone: rules.validate_phone,
    medicalDesignation: rules.validate_medical_designation,
    institution: rules.validate_institution,
    clinicName: rules.validate_clinic,
  }),
  update_subscriber_schema: yup.object().shape({
    firstName: rules.validate_first_name,
    lastName: rules.validate_last_name,
    email: rules.validate_email,
    mobile: rules.validate_phone,
    medicalDesignation: rules.validate_medical_designation,
    clinicId: rules.validate_institution,
    clinicName: rules.validate_clinic,
    tags: rules.validate_tags,
    zip: rules.validate_zip,
    channels: rules.validate_channels,
    sendType: yup.object().shape({
      email: yup.boolean().required(),
      sms: yup.boolean().required(),
    }),
  }),
  video_schema: yup.object().shape({
    title: rules.validate_video_title,
    longVideoTitle: rules.validate_long_video_title,
    description: rules.validate_description,
    shortVideoLink: rules.validate_short_link,
    longVideoLink: rules.validate_link,
    // doctorName: rules.validate_doctor_name,
    series: rules.validate_series,
    mds: rules.validate_mds,
    tags: rules.validate_tags,
    thumbnailFile: rules.validate_thumbnail_file,
    scheduledOn: rules.validate_scheduled_on,
    makeActiveOn: rules.validate_make_active_on,
    shortVideoMessage: rules.short_video_message,
    longVideoMessage: rules.long_video_message,
    authors: rules.authors_message,
  }),
  video_file_upload: yup.object().shape({
    file: rules.validate_video_file,
  }),
  general_setting: yup.object().shape({
    name: rules.validate_account_name,
    slug: rules.validate_account_slug,
    logo: rules.validate_account_logo,
    videoThumbnailFile: rules.validate_thumbnail_file,
  }),
  user_invite: yup.object().shape({
    email: rules.validate_email,
  }),
  add_account: yup.object().shape({
    email: rules.validate_email,
    companyName: rules.validate_company_name,
    slug: rules.validate_slug,
    parentAccount: rules.validate_account_id_optional,
  }),
  add_message: yup.object().shape({
    type: rules.validate_message_type,
    message: rules.validate_message_text,
  }),
  profile_update: yup.object().shape({
    firstName: rules.validate_first_name,
    lastName: rules.validate_last_name,
    email: rules.validate_email,
    mobile: rules.validate_phone,
  }),
  password_update: yup.object().shape({
    currentPassword: rules.validate_current_password,
    newPassword: rules.validate_password,
    confirmPassword: rules.validate_confirm_password,
  }),
  channel_schema: yup.object().shape({
    name: rules.validate_channel_name,
    description: rules.validate_description,
  }),
  clinic_schema: yup.object().shape({
    name: rules.validate_clinic_name,
    description: rules.validate_description,
  }),
  tag_schema: yup.object().shape({
    tag: rules.validate_tag_name,
  }),
  assign_clinic_to_account_schema: yup.object().shape({
    accountId: rules.validate_account_id,
  }),
  doctor_schema: yup.object().shape({
    firstName: rules.validate_first_name,
    lastName: rules.validate_last_name,
    email: rules.validate_email,
    mobile: rules.validate_phone_not_required,
  }),
  video_series_schema: yup.object().shape({
    name: rules.validate_name,
    description: rules.validate_description,
  }),
};
