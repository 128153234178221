import { Chip, Link } from "@mui/material";

import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Table from "components/Core/Table/Table";

import { drPrefix, formatDate, thumbnail } from "helpers/utils";
// import ChipEllipsis from "components/Core/ChipEllipsis";

import MoreOptions from "components/Core/MoreOptions";

import pages from "enums/pages";

import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
import BarChartIcon from "@mui/icons-material/BarChart";
import BiotechIcon from "@mui/icons-material/Biotech";
import ViewListIcon from "@mui/icons-material/ViewList";
import { ROLES } from "enums/common";
// import { useDialog } from "context/dialog";
import { useCurrentState } from "context/global";

const columnHelper = createColumnHelper<any>();

type Props = {
  videos: any[];
};

const Videos = ({ videos = [] }: Props) => {
  // const { confirm, alertSuccess, alertError, showPageLoading } = useDialog();
  const { globalState } = useCurrentState();
  const role = globalState?.role;

  // const handleDeleteVideo = async (id: string) => {
  //   showPageLoading(true);
  //   try {
  //     console.log(id);
  //     // await gqlDeleteVideo(id);
  //     alertSuccess("Video deleted successfully");
  //     // setTriggerRefresh((prev: number) => prev + 1);
  //   } catch (err) {
  //     alertError("Something went wrong!");
  //   } finally {
  //     showPageLoading(false);
  //   }
  // };

  // const confirmVideoDeletion = (id: string, videoTitle: string) => {
  //   confirm({
  //     title: "Delete Video!",
  //     content: `Are you sure you want to delete ${videoTitle}?`,
  //     cofirmText: "Delete",
  //     cancelText: "Cancel",
  //     onConfirm: () => handleDeleteVideo(id),
  //   });
  // };

  const columns: any = [
    columnHelper.accessor("player", {
      id: "player",
      header: "",
      cell: ({ row }: any) => {
        return row.original.short_video_link || row.original.video_link ? (
          <img src={thumbnail(row.original, true)} width="140px" height="75px" />
        ) : (
          <></>
        );
      },
    }),
    columnHelper.accessor("title", {
      id: "title",
      header: "Title",
      cell: ({ row }: any) => {
        const dashboard =
          pages.VIDEO_DASHBOARD_PAGE.replace(":videoId", row.original.id) +
          `?name=${row.original.title}&rate=${row.original.avg_rating}&rc=${row.original.rating_count}`;
        return globalState?.role === ROLES.LITE_CHANNEL_ADMIN ? (
          row.original.title
        ) : (
          <Link href={dashboard} underline="none" color="inherit">
            {row.original.title}
          </Link>
        );
      },
    }),
    columnHelper.accessor("authors", {
      id: "authors",
      header: "Doctor Name",
      cell: (info) => {
        const row = info.row.original;
        const doctors =
          (row.authors ?? []).map((author: any) => author.name).join(", ") ||
          row.doctor_name;
        return doctors ? drPrefix(doctors) : "";
      },
    }),
    // columnHelper.accessor("tags", {
    //   id: "tags",
    //   header: "Tags",
    //   cell: (info) => (
    //     <ChipEllipsis items={info.getValue()?.map((tag: any) => tag.tag)} />
    //   ),
    // }),
    columnHelper.accessor("status", {
      id: "status",
      header: "Status",
      cell: (info) => (
        <Chip
          label={info.getValue().toLowerCase()}
          color={info.getValue() === "ACTIVE" ? "success" : "error"}
          style={{ textTransform: "capitalize" }}
        />
      ),
    }),
    // columnHelper.accessor("poll_display_count", {
    //   id: "poll_display_count",
    //   header: "Poll Display Count",
    // }),
    // columnHelper.accessor("poll_response_count", {
    //   id: "poll_response_count",
    //   header: "Poll Response Count",
    // }),

    // columnHelper.accessor("poll_created_at", {
    //   id: "poll_created_at",
    //   header: "Poll Created Date",
    //   cell: (info) => formatDate(info.getValue()),
    // }),
    columnHelper.accessor("scheduled_on", {
      id: "scheduled_on",
      header: "Scheduled For",
      cell: (info) => formatDate(info.getValue(), true),
      enableSorting: true,
    }),
    columnHelper.accessor("created_at", {
      id: "created_date",
      header: "Created Date",
      cell: (info) => formatDate(info.getValue()),
    }),
    columnHelper.accessor("action", {
      id: "action",
      header: "",
      cell: ({ row }: any) => {
        const videoLink = `${pages.VIDEOS_PAGE.replace(":id", row.original.id)}`;
        return (
          <MoreOptions
            options={[
              {
                label: "Edit",
                icon: <EditIcon width={24} height={24} />,
                link: videoLink,
              },
              {
                label: "Polling",
                icon: <BarChartIcon width={24} height={24} />,
                link: videoLink + "#polling",
              },
              ...(role !== ROLES.LITE_CHANNEL_ADMIN
                ? [
                    {
                      label: "Logs",
                      icon: <ViewListIcon width={24} height={24} />,
                      link: videoLink + "#videologs",
                    },
                  ]
                : []),
              {
                label: "Test",
                icon: <BiotechIcon width={24} height={24} />,
                link: videoLink + "#test",
              },
              // {
              //   label: "Delete",
              //   icon: <DeleteIcon width={24} height={24} />,
              //   onClick: () => confirmVideoDeletion(row.original.id, row.original.title),
              // },
            ]}
          />
        );
      },
    }),
  ];

  const table: any = useReactTable({
    data: videos,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return <Table table={table} showPagination={false} />;
};

export default Videos;
