import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { StyledTableCell } from "./Table.styles";
import { Table as TableProps, flexRender } from "@tanstack/react-table";
import useQuery from "hooks/useQuery";
import React from "react";
import { Typography } from "@mui/material";

type Props = {
  table: TableProps<unknown | any>;
  totalCount?: number;
  rowsPerPage?: number;
  showPagination?: boolean;
};

const Table = ({
  table,
  totalCount = 0,
  rowsPerPage = 10,
  showPagination = true,
}: Props) => {
  const { params, updateQueryParams } = useQuery();
  const page = +params.page || 0;

  return (
    <>
      <TableContainer>
        <MuiTable>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <StyledTableCell key={header.id}>
                    {header.column.columnDef.enableSorting ? (
                      <TableSortLabel
                        active={!!header.column.getIsSorted()}
                        direction={header.column.getIsSorted() || undefined}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableSortLabel>
                    ) : header.isPlaceholder ? null : (
                      flexRender(header.column.columnDef.header, header.getContext())
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <React.Fragment key={row.id}>
                <TableRow>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
                {row.getIsExpanded() && (
                  <TableRow>
                    <TableCell
                      colSpan={table.getVisibleFlatColumns().length}
                      style={{ paddingLeft: "2em", paddingRight: "2em" }}
                    >
                      <Typography variant="h5">
                        <p>Questions:</p>
                      </Typography>
                      {row.original.pollStats?.map((poll: any, index: number) => {
                        return (
                          <div key={poll.id} style={{ marginBottom: "1em" }}>
                            <Typography variant="h5">
                              {index + 1}.{poll.question}
                              <div style={{ textAlign: "right" }}>
                                Total Response: {poll.count}
                              </div>
                            </Typography>
                            <div
                              style={{ display: "flex", justifyContent: "space-between" }}
                            >
                              <Typography variant="h6">
                                <p>Answers:</p>
                              </Typography>
                              <Typography variant="h6">
                                <p>Count</p>
                              </Typography>
                            </div>
                            {poll.responses.map((response: any) => {
                              return (
                                <div key={response.response}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <p>{response.response}</p>
                                    <p>{response.count}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
            {!table.getRowModel().rows.length ? (
              <TableRow key={"no-record-found"}>
                <TableCell colSpan={table.getVisibleFlatColumns().length} align="center">
                  No Data Found
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>

      {showPagination && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[0]}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => {
            updateQueryParams({ page: newPage });
          }}
        />
      )}
    </>
  );
};

export default Table;
