import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { getVideoSeriesWithVideos } from "api/api";
// import ChipEllipsis from "components/Core/ChipEllipsis";
import useQuery from "hooks/useQuery";
import AddIcon from "@mui/icons-material/Add";
import { useDialog } from "context/dialog";
import Videos from "./Videos";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { thumbnail } from "helpers/utils";
import palette from "theme/palette";
import Logo from "components/Core/Logo";
import pages from "enums/pages";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import EditIcon from "@mui/icons-material/Edit";
import MoreOptions from "components/Core/MoreOptions";

const VideoSeries = () => {
  const { params, updateQueryParams } = useQuery();
  const navigate = useNavigate();
  const limit = 20;
  const page = +params.page || 0;

  const { showPageLoading } = useDialog();

  const [videoSeries, setVideoSeries] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const fetchData = useCallback(
    async (page, limit) => {
      try {
        showPageLoading(true);

        const res: any = await getVideoSeriesWithVideos(limit, page);
        const data = res.videoSeries;
        setVideoSeries(data.rows);
        setTotalCount(data.count);
      } catch (error) {
        //
      } finally {
        showPageLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    fetchData(page, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);

  return (
    <Container maxWidth={"xl"}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Video Series
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            navigate(pages.EDIT_VIDEO_SERIES.replace(":id", uuid()) + "?new=true");
          }}
          startIcon={<AddIcon />}
          size="large"
        >
          New Video Series
        </Button>
      </Stack>

      <Stack gap={1}>
        {videoSeries?.map((series: any, index: number) => (
          <Accordion
            key={index}
            sx={{
              border: "1px solid " + palette.grey[300],
              borderRadius: 1,
              "&:before": { opacity: 0 },
            }}
          >
            <AccordionSummary id={series.id}>
              <Grid container>
                <Grid item md={3}>
                  {series?.Videos?.[0]?.short_video_link ||
                  series?.Videos?.[0]?.video_link ? (
                    <img
                      src={thumbnail(series?.Videos?.[0], true)}
                      width="240px"
                      height="125px"
                    />
                  ) : (
                    <Box sx={{ opacity: 0.3 }}>
                      <Logo width={240} height={90} />
                    </Box>
                  )}
                </Grid>
                <Grid item md={8.5}>
                  <Typography fontSize={16} fontWeight={600}>
                    {series.name}
                  </Typography>

                  <Typography fontSize={14}>{series.description}</Typography>
                </Grid>
                <Grid item md={0.5}>
                  <Stack alignItems={"center"} justifyContent={"center"} height={1}>
                    <MoreOptions
                      options={[
                        {
                          label: "Edit",
                          icon: <EditIcon />,
                          link: `${pages.EDIT_VIDEO_SERIES.replace(":id", series.id)}`,
                        },
                      ]}
                    />
                  </Stack>
                </Grid>
              </Grid>
              {/* <Stack gap={2} direction={"row"}>
                {series?.Videos?.[0]?.short_video_link ||
                series?.Videos?.[0]?.video_link ? (
                  <img
                    src={thumbnail(series?.Videos?.[0], true)}
                    width="240px"
                    height="125px"
                  />
                ) : (
                  <Box sx={{ opacity: 0.3 }}>
                    <Logo width={240} height={90} />
                  </Box>
                )}

                <Stack>
                  
                    <Typography fontSize={16} fontWeight={600}>
                      {series.name}
                    </Typography>
                

                  <Typography fontSize={14}>{series.description}</Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"}>
                  <MoreOptions
                    options={[
                      {
                        label: "Edit",
                        icon: "#",
                        link: "#",
                      },
                    ]}
                  />
                </Stack>
              </Stack> */}
            </AccordionSummary>
            <AccordionDetails>
              <Videos videos={series.Videos ?? []} />
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>

      <TablePagination
        component="div"
        rowsPerPageOptions={[0]}
        count={totalCount}
        rowsPerPage={limit}
        page={page}
        onPageChange={(event, newPage) => {
          updateQueryParams({ page: newPage });
        }}
      />
    </Container>
  );
};

export default VideoSeries;
