import { Button, Card, Chip, Container, Link, Stack, Typography } from "@mui/material";
import Scrollbar from "components/Core/Scrollbar";

import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Table from "components/Core/Table/Table";

import RowSelection from "components/Core/Table/RowSelection";
import { useCallback, useEffect, useState } from "react";
import { addVideosToChannel, addVideosToSeries } from "api/api";
import { drPrefix, formatDate, localStorageGetItem, thumbnail } from "helpers/utils";
import ChipEllipsis from "components/Core/ChipEllipsis";

import MoreOptions from "components/Core/MoreOptions";

import pages from "enums/pages";
import useQuery from "hooks/useQuery";
import { useCurrentUser } from "context/user";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import BarChartIcon from "@mui/icons-material/BarChart";
import BiotechIcon from "@mui/icons-material/Biotech";
import AddIcon from "@mui/icons-material/Add";
import ViewListIcon from "@mui/icons-material/ViewList";
import Filters from "./Filters";
import { useDialog } from "context/dialog";
import { ROLES } from "enums/common";
import { useCurrentState } from "context/global";

// import { deleteVideo,getVideos } from "api/api";
import { deleteVideo as gqlDeleteVideo, getVideos as gqlGetVideos } from "api/graphql";

const columnHelper = createColumnHelper<any>();

const Videos = () => {
  const { params } = useQuery();
  const limit = 20;
  const page = +params.page || 0;
  const search = params.search || "";
  const videoFilter = params.video || "all";
  const channelId = params.channelid;
  const seriesId = params.seriesid;

  const { user } = useCurrentUser();
  const { globalState } = useCurrentState();
  const role = globalState?.role;
  const navigate = useNavigate();
  const { confirm, alertSuccess, alertError, showPageLoading } = useDialog();

  const [triggerRefresh, setTriggerRefresh] = useState(0);
  const [videos, setVideos] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sorting, setSorting] = useState<SortingState>([]);

  const excludeColFromEdit: any = {
    poll_response_count: user?.isSuperAdmin ? true : false,
    poll_created_at: user?.isSuperAdmin ? true : false,
  };

  const [columnVisibility, setColumnVisibility] = useState<any>({
    select: channelId || seriesId ? true : false,
    action: channelId || seriesId ? false : true,
    poll_response_count: user?.isSuperAdmin ? true : false,
    poll_created_at: user?.isSuperAdmin ? true : false,
  });

  const fetchData = useCallback(
    async (channelId, seriesId, search, videoFilter, sorting, page, limit) => {
      try {
        showPageLoading(true);
        const sort = sorting.length
          ? `${sorting[0].id}:${sorting[0].desc ? "desc" : "asc"}`
          : "";

        const filter: any = { search, videoFilter, sort };
        if (channelId) filter.status = "INACTIVE";
        if (seriesId) filter.seriesId = "null";
        const res: any = await gqlGetVideos("", filter, limit, page);
        const data = res.data;
        setVideos(data.rows);
        setTotalCount(data.count);
      } catch (error) {
        //
      } finally {
        showPageLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    fetchData(channelId, seriesId, search, videoFilter, sorting, page, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId, page, limit, search, videoFilter, sorting, triggerRefresh, seriesId]);

  // table
  const columns: any = [
    {
      id: "select",
      header: ({ table }: any) => (
        <RowSelection
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }: any) => (
        <RowSelection
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    columnHelper.accessor("player", {
      id: "player",
      header: "",
      cell: ({ row }: any) => {
        return row.original.short_video_link || row.original.video_link ? (
          <img src={thumbnail(row.original, true)} width="140px" height="75px" />
        ) : (
          <></>
        );
      },
    }),
    columnHelper.accessor("title", {
      id: "title",
      header: "Title",
      cell: ({ row }: any) => {
        const dashboard =
          pages.VIDEO_DASHBOARD_PAGE.replace(":videoId", row.original.id) +
          `?name=${row.original.title}&rate=${row.original.avg_rating}&rc=${row.original.rating_count}`;
        return globalState?.role === ROLES.LITE_CHANNEL_ADMIN ? (
          row.original.title
        ) : (
          <Link href={dashboard} underline="none" color="inherit">
            {row.original.title}
          </Link>
        );
      },
    }),
    columnHelper.accessor("authors", {
      id: "authors",
      header: "Doctor Name",
      cell: (info) => {
        const row = info.row.original;
        const doctors =
          (row.authors ?? []).map((author: any) => author.name).join(", ") ||
          row.doctor_name;
        return doctors ? drPrefix(doctors) : "";
      },
    }),
    columnHelper.accessor("VideoSeries", {
      id: "series",
      header: "Series",
      cell: (info) => info.getValue()?.name ?? "",
    }),
    columnHelper.accessor("tags", {
      id: "tags",
      header: "Tags",
      cell: (info) => (
        <ChipEllipsis items={info.getValue()?.map((tag: any) => tag.tag)} />
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: "Status",
      cell: (info) => (
        <Chip
          label={info.getValue().toLowerCase()}
          color={info.getValue() === "ACTIVE" ? "success" : "error"}
          style={{ textTransform: "capitalize" }}
        />
      ),
    }),
    columnHelper.accessor("poll_display_count", {
      id: "poll_display_count",
      header: "Poll Display Count",
    }),
    columnHelper.accessor("poll_response_count", {
      id: "poll_response_count",
      header: "Poll Response Count",
    }),

    columnHelper.accessor("poll_created_at", {
      id: "poll_created_at",
      header: "Poll Created Date",
      cell: (info) => formatDate(info.getValue()),
    }),
    columnHelper.accessor("scheduled_on", {
      id: "scheduled_on",
      header: "Scheduled For",
      cell: (info) => formatDate(info.getValue(), true),
      enableSorting: true,
    }),
    columnHelper.accessor("created_at", {
      id: "created_date",
      header: "Created Date",
      cell: (info) => formatDate(info.getValue()),
    }),
    columnHelper.accessor("action", {
      id: "action",
      header: "",
      cell: ({ row }: any) => {
        const videoLink = `${pages.VIDEOS_PAGE.replace(":id", row.original.id)}`;
        return (
          <MoreOptions
            options={[
              {
                label: "Edit",
                icon: <EditIcon width={24} height={24} />,
                link: videoLink,
              },
              {
                label: "Polling",
                icon: <BarChartIcon width={24} height={24} />,
                link: videoLink + "#polling",
              },
              ...(role !== ROLES.LITE_CHANNEL_ADMIN
                ? [
                    {
                      label: "Logs",
                      icon: <ViewListIcon width={24} height={24} />,
                      link: videoLink + "#videologs",
                    },
                  ]
                : []),
              {
                label: "Test",
                icon: <BiotechIcon width={24} height={24} />,
                link: videoLink + "#test",
              },
              {
                label: "Delete",
                icon: <DeleteIcon width={24} height={24} />,
                onClick: () => confirmVideoDeletion(row.original.id, row.original.title),
              },
            ]}
          />
        );
      },
    }),
  ];

  const table = useReactTable({
    data: videos,
    columns,
    state: { sorting, columnVisibility },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
  });

  // save selected videos into channel
  const selectVideosId = table
    .getSelectedRowModel()
    .rows.map(({ original }) => original.id);

  const saveChannelVideos = async () => {
    if (channelId) {
      try {
        showPageLoading(true);
        await addVideosToChannel(channelId, selectVideosId);
        navigate(
          pages.CHANNELS_VIDEOS_PAGE.replace(":id", channelId) +
            `?count=${selectVideosId.length}&module=video`,
        );
      } catch (error: any) {
        alertError(error.message);
      } finally {
        showPageLoading(false);
      }
    }
  };

  const saveVideosToSeries = async () => {
    if (seriesId) {
      try {
        showPageLoading(true);
        await addVideosToSeries(seriesId, selectVideosId);
        navigate(pages.EDIT_VIDEO_SERIES.replace(":id", seriesId));
      } catch (error: any) {
        alertError(error.message);
      } finally {
        showPageLoading(false);
      }
    }
  };

  const visibleColums = columns
    .filter((col: any) => col.header && typeof col.header === "string")
    .filter((col: any) =>
      excludeColFromEdit[col.id] !== undefined ? excludeColFromEdit[col.id] : true,
    )
    .map((col: any) => ({ value: col.id, label: col.header }));

  // hide/show columns
  const updateColumnVisibility = useCallback(() => {
    const storedColumns = JSON.parse(localStorageGetItem("videoTableColumns") || "[]");

    if (storedColumns.length) {
      setColumnVisibility((prev: any) => ({
        ...prev,
        ...Object.fromEntries(storedColumns.map((col: string) => [col, true])),
        ...Object.fromEntries(
          visibleColums
            .filter((col: any) => !storedColumns.includes(col.value))
            .map((col: any) => [col.value, false]),
        ),
      }));
    } else {
      setColumnVisibility((prev: any) => ({
        ...prev,
        ...Object.fromEntries(visibleColums.map((col: any) => [col.value, true])),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateColumnVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteVideo = async (id: string) => {
    showPageLoading(true);
    try {
      await gqlDeleteVideo(id);
      alertSuccess("Video deleted successfully");
      setTriggerRefresh((prev: number) => prev + 1);
    } catch (err) {
      alertError("Something went wrong!");
    } finally {
      showPageLoading(false);
    }
  };

  const confirmVideoDeletion = (id: string, videoTitle: string) => {
    confirm({
      title: "Delete Video!",
      content: `Are you sure you want to delete ${videoTitle}?`,
      cofirmText: "Delete",
      cancelText: "Cancel",
      onConfirm: () => handleDeleteVideo(id),
    });
  };

  return (
    <Container maxWidth={"xl"}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Videos
        </Typography>
        {!channelId && !seriesId && (
          <Button
            variant="contained"
            onClick={() => {
              navigate(pages.VIDEOS_PAGE.replace(":id", uuid()) + "?new=true");
            }}
            startIcon={<AddIcon />}
            size="large"
          >
            New Video
          </Button>
        )}

        {channelId && (
          <Button
            variant="contained"
            onClick={saveChannelVideos}
            startIcon={<AddIcon />}
            disabled={selectVideosId.length ? false : true}
            size="large"
          >
            Add Videos To Channel
          </Button>
        )}

        {seriesId && (
          <Button
            variant="contained"
            onClick={saveVideosToSeries}
            startIcon={<AddIcon />}
            disabled={selectVideosId.length ? false : true}
            size="large"
          >
            Add Videos To Series
          </Button>
        )}
      </Stack>

      <Card>
        <Filters
          columns={visibleColums}
          updateColumnVisibility={updateColumnVisibility}
        />
        <Scrollbar>
          <Table table={table} totalCount={totalCount} rowsPerPage={limit} />
        </Scrollbar>
      </Card>
    </Container>
  );
};

export default Videos;
