export default {
  VALIDATION: {
    FIRST_NAME: {
      REQUIRED: "First Name is a required field",
    },
    LAST_NAME: {
      REQUIRED: "Last Name is a required field",
    },
    EMAIL: {
      REQUIRED: "Email is a required field",
      VALID_EMAIL: "Email must be a valid email",
    },
    PASSWORD: {
      REQUIRED: "Password is a required field",
      MIN_8: "Password must contain at least 8 characters",
      CONTAIN_NUMBER: "Password must contain a number",
      CONTAIN_LOWERCASE: "Password must contain a lower case letter",
      CONTAIN_UPPERCASE: "Password must contain an upper case letter",
      CONTAIN_SPECIAL_CHAR: "Password must contain a special character",
    },
    PHONE: {
      REQUIRED: "Phone number is a required field",
      VALID_PHONE: "Enter valid phone number",
    },
    MEDICAL_DESIGNATION: {
      REQUIRED: "Medical designation is a required field",
    },
    CLINIC_NAME: {
      REQUIRED: "Clinic Name is a required field",
    },
    CHANNEL_NAME: {
      REQUIRED: "Channel Name is a required field",
    },
    NAME: {
      REQUIRED: "Name is a required field",
    },
    TAG_NAME: {
      REQUIRED: "Tag Name is a required field",
    },
    INSTITUTION: {
      REQUIRED: "Institution/Clinic is a required field",
    },
    VIDEO_TITLE: {
      REQUIRED: "Title is a required field",
    },
    LONG_VIDEO_TITLE: {
      REQUIRED: "Long Video Title is a required field",
    },
    DESCRIPTION: {
      REQUIRED: "Description is a required field",
    },
    VIDEO_FILE: {
      REQUIRED: "Please select file",
      VALID_FILE: "Selected file is not a valid file",
    },
    LINK: {
      VALID_LINK: "Link must be a valid link",
      REQUIRED: "Please enter the link or upload video",
    },
    COMPANY_NAME: {
      REQUIRED: "Company name field is a required field",
    },
    SLUG: {
      REQUIRED: "Slug field is a required field",
    },
    DOCTOR_NAME: {
      REQUIRED: "Doctor Name is a required field",
    },
    MDS: {
      REQUIRED: "Select atleast one type of Target Audience",
    },
    THUMBNAIL_FILE: {
      SIZE: "Thumbnail size must be less than 5MB",
    },
    ACCOUNT_NAME: {
      REQUIRED: "Name is a required field",
    },
    ACCOUNT_SLUG: {
      REQUIRED: "Slug is a required field",
    },
    ACCOUNT_LOGO: {
      REQUIRED: "Logo is a required field",
      SIZE: "Thumbnail size must be less than 5MB",
    },
    ACCOUNT_ID: {
      REQUIRED: "Please select account",
    },
    DATETIME: {
      REQUIRED: "Date time field is required",
      PASTDATE: "Select future datetime only",
    },
    SHORT_VIDEO_MESSAGE: {
      INCLUDE_PLACEHOLDERS: "Field must inculdes this placeholder {{LINK}}",
    },
    LONG_VIDEO_MESSAGE: {
      INCLUDE_PLACEHOLDERS: "Field must inculdes this placeholder {{LINK}}",
    },
    CURRENT_PASSWORD: {
      REQUIRED: "Current password is a required field",
    },
    AUTHORS: {
      REQUIRED: "Authors is a required field",
    },
    CONFIRM_PASSWORD: {
      REQUIRED: "Confirm password is a required field",
      NOT_MATCHING: "New password and confirm password must match",
    },
    SCHEDULED_ON: {
      REQUIRED: "Schedule Video field is required",
      DEPENDS_ON_MAKE_ACTIVE_ON:
        "Either schedule video Or Make Video Active field is required",
      SCHEDULED_ON_HOLIDAY: "Video can not be scheduled on holiday",
    },
    MAKE_ACTIVE_ON: {
      REQUIRED: "Make Video Active field is required",
      DEPENDS_ON_SCHEDULED_ON:
        "Either schedule video Or Make Video Active field is required",
    },
    ZIP: {
      REQUIRED: "Zip code is a required field",
      VALID_ZIP: "Zip allowed from US or Canada",
    },
    MESSAGE_TYPE: {
      REQUIRED: "Message Type is a required field",
    },
    MESSAGE_TEXT: {
      REQUIRED: "Message is a required field",
    },
  },
};
